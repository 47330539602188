<template>
  <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit"
         @click="editTranslate([
        'payments_InvoiceDHLID',
        'payments_InvoiceTNTID',
        'payments_InvoiceFEDEXID',
        'payments_InvoiceNPID',
        'payments_InvoiceUPSID',
        'payments_InvoiceCDEKID',
        'payments_balanceOutputCommission',
        'payments_balanceOutputId',
        'payments_balanceOutputIdCorrection',
        'payments_balanceOutputCommissionCorrection',
        'payments_invoiceDisposal',
        'payments_invoiceMeest',
      ])"></div>

    <template v-if="orderInstance || (hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).model === 'order_express')">
      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceDHLID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=DHLFedExTNT&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>

      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceTNTID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=DHLFedExTNT&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>

      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceFEDEXID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=DHLFedExTNT&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>

      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceNPGID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=DHLFedExTNT&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>

      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.UPS_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceUPSID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=DHLFedExTNT&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>



      <!--  ******************** REQUEST FUNDS ***********************-->

      <template v-if="_.has(orderInstance, 'balance_before_output')">

        <template v-if="item.payment_type === 'add_money'">

          <!--   If transaction from other user  -->
          <template v-if="item.comment.indexOf('Funds Requests id') === 0">{{$t('payments_balanceOutputOtherUser.localization_value.value')}}
            {{ item.orderInstance.account.user.user_personal_contact.user_full_name }}
          </template>

          <!--   v-else  -->
          <template v-else>
            <template v-if="item.comment.indexOf('Commission corrected by Funds Requests id') === 0">{{$t('payments_balanceOutputCommissionCorrection.localization_value.value')}}</template>
            <template v-if="item.comment.indexOf('Corrected money by Funds Requests id') === 0">{{$t('payments_balanceOutputIdCorrection.localization_value.value')}}</template>
            <router-link class="table-link btn-style"
                         :to="`${$store.getters.GET_PATHS.financeRequestFunds}?page=1&count=25&id=${item.order_id}`">
              {{ item.order_id }}
            </router-link>
          </template>

        </template>
        <template v-if="item.payment_type === 'remove_money'">
          <template v-if="item.comment.indexOf('Commission by Funds Requests id') === 0">{{$t('payments_balanceOutputCommission.localization_value.value')}}</template>
          <template v-if="item.comment.indexOf('Funds Requests id') === 0">{{$t('payments_balanceOutputId.localization_value.value')}}</template>
          <template v-if="item.comment.indexOf('Commission corrected by Funds Requests id') === 0">{{$t('payments_balanceOutputCommissionCorrection.localization_value.value')}}</template>
          <template v-if="item.comment.indexOf('Corrected money by Funds Requests id') === 0">{{$t('payments_balanceOutputIdCorrection.localization_value.value')}}</template>
          <router-link class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeRequestFunds}?page=1&count=25&id=${item.order_id}`">
            {{ item.order_id }}
          </router-link>
        </template>
      </template>
    </template>

<!--    ORDER INTERNAL -->
    <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).model === 'order_internal'">
      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.NOVAPOSHTA_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceNPID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=novaPoshta&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>

      <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id">
        {{$t('payments_InvoiceCDEKID.localization_value.value')}}
        <span class="table-link btn-style"
                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=cdek&id=${item.invoice.id}`">
          {{ item.invoice.id }}
        </span>
      </template>
    </template>


    <!--    ORDER INTERNAL -->
    <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).model === 'return_document'">
      <template v-if="hasInvoiceInstance(item).instance.delivery_service && hasInvoiceInstance(item).instance.delivery_service.code === 'meest'">
        {{$t('payments_invoiceMeest.localization_value.value')}}
        <span>
          {{ item.invoice.id }}
        </span>
      </template>
    </template>

    <template v-if="invoice = item.invoice">
      <template v-if="invoiceInstance = invoice.invoice_instance">
        <template v-if="_.has(invoiceInstance.instance, 'amazon_sets_cost')">
          {{$t('payments_InvoiceFBA.localization_value.value')}}
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=FBA&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>
        <template v-if="invoiceInstance.model === 'order_fbm'">
          {{$t('payments_InvoiceFBM.localization_value.value')}}
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=orders&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>
        <template v-if="_.has(invoiceInstance.instance, 'label_presence') && invoice.invoice_type_id !== 5">
          {{$t('payments_InvoiceShipment.localization_value.value')}}
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=inbound&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>
        <template v-if="_.has(invoiceInstance.instance, 'label_presence') && invoice.invoice_type_id === 5">
          {{$t('payments_StorageCost.localization_value.value')}}
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=other&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>
        <template v-if="_.has(invoiceInstance.instance, 'consolidation_destination_id')">
          {{$t('payments_InvoiceConso.localization_value.value')}}
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=consolidation&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>


        <template v-if="invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id && item.payment_type === 'remove_money'">
          {{$t('payments_invoiceDisposal.localization_value.value')}}:
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=other&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>

        <!--  ******************** SMS ***********************-->
        <template v-if="invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_SMS.id">
          {{$t('payments_invoiceSms.localization_value.value')}}:
          <span class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=other&id=${invoice.id}`">
            {{ invoice.id }}
          </span>
        </template>

        <!--  ******************** LISTINGS ***********************-->

        <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).model === 'listing'">
          {{$t('payments_listings.localization_value.value')}}
          <!--        <router-link class="table-link btn-style"-->
          <!--                     :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=cdek&id=${item.invoice.id}`">-->
          <!--          {{ item.invoice.id }}-->
          <!--        </router-link>-->
        </template>
      </template>

      <template v-else>

        <template v-if="
        (invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id || invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id)
        && item.payment_type === 'remove_money'">
          {{$t('payments_invoiceOther.localization_value.value')}}:
          <router-link class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=other&id=${invoice.id}`">
            {{ invoice.id }}
          </router-link>
        </template>
        <template v-if="invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id && item.payment_type === 'add_money'">
          {{$t('payments_invoiceOrderRefund.localization_value.value')}}:
          <router-link class="table-link btn-style"
                       :to="`${$store.getters.GET_PATHS.financeInvoices}?page=1&count=25&type=other&id=${invoice.id}`">
            {{ invoice.id }}
          </router-link>
        </template>
      </template>
    </template>

  </div>
</template>

<script>
import {
  EXPRESS_DELIVERY_SERVICES,
  INTERNAL_DELIVERY_SERVICES, INVOICES_TYPES
} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "BalanceHistoryTableComment",
    components: {},
    props: {
      item: Object,
      orderInstance: Object,
      payedInvoice: Object,
    },

    data() {
      return {
        EXPRESS_DELIVERY_SERVICES: EXPRESS_DELIVERY_SERVICES,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        INVOICES_TYPES: INVOICES_TYPES,
      }
    },

    methods: {
      hasInvoiceInstance(item){
        return item?.invoice?.invoice_instance
      },
    }

  }
</script>

<style scoped>
  .comment-row {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  span.table-link.btn-style{
    text-decoration: none;
    color: #232323;
    cursor: initial;
  }
</style>
